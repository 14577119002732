<template>
  <div class="login-page">
    <div class="login-page__content">
      <main-logo class="login-page__logo" />
      <router-view></router-view>
      <div class="login-page__title standart-heading">
        {{ $t(`titles.${$route.name}`) }}
      </div>
      <transition name="slide-fade-auth" mode="out-in">
        <page-block>
          <div class="l-col-12_sm-9_md-9 l-flex">
            <div v-for="item in menu" :key="item.key" class="l-col-6_sm-4">
              <think-card
                v-if="isAuth"
                :icon="item.icon"
                :text="$t(`${item.key}`)"
                :to="item.to || null"
              />
              <think-card
                v-else
                :icon="item.icon"
                :text="$t(`${item.key}`)"
                :link="item.link || null"
                @click="gotoLink(item.link)"
              />
            </div>
          </div>
          <div class="l-col-12_sm-6_md-5"></div>
        </page-block>
      </transition>
      <div class="login-page__nav-bottom">
        <base-button :to="{ name: 'start' }" class="login-page__submit">
          {{ $t('signin') }}
        </base-button>
      </div>
    </div>
    <div class="login-page__card">
      <main-banner class="tariff-card">
        <template #header>
          <div class="tariff-card__title-high big-title">
            {{ $t('blog.title') }}
          </div>
        </template>
        <div class="tariff-card__content">
          <div class="tariff-card__activity">
            <div class="tariff-card__title standart-title">{{ $t('blog.first') }}</div>
          </div>
        </div>
        <div class="tariff-card__content">
          <div class="tariff-card__activity">
            <div class="tariff-card__title standart-title">{{ $t('blog.second') }}</div>
          </div>
        </div>
        <div class="tariff-card__content">
          <div class="tariff-card__activity">
            <div class="tariff-card__title standart-title">{{ $t('blog.third') }}</div>
          </div>
        </div>
        <template #footer>
          <div class="tariff-card__content">
            <div class="tariff-card__activity">
              <div class="tariff-card__title standart-title">{{ $t('blog.fourth') }}</div>
            </div>
          </div>
        </template>
        <template #footerEnd>
          <base-button target="_blank" color="primary" @click="linkTo">
            {{ $t('read') }}
          </base-button>
        </template>
      </main-banner>
    </div>
  </div>
</template>

<script>
import MainLogo from '@/components/MainLogo/MainLogo';
import ThinkCard from './components/ThinkCard.vue';
import MainBanner from '@/components/MainBanner/MainBanner';
export default {
  name: 'ThinkPage',
  components: {
    MainBanner,
    MainLogo,
    ThinkCard,
  },

  data() {
    return {
      topNavRaw: [
        {
          key: 'signin',
          to: { name: 'Auth' },
          show: ['Auth', 'Reg'],
        },
        {
          key: 'signup',
          to: { name: 'Reg' },
          show: ['Auth', 'Reg'],
        },
      ],
      servicesGrid: [
        {
          key: 'vps',
          icon: 'vds',
          to: { name: 'VPS' },
        },
        {
          key: 'clouds',
          icon: 'vdc3',
          to: { name: 'Stack' },
        },
        {
          key: 'hosting',
          icon: 'vhost',
          to: { name: 'vhost' },
        },
        {
          key: 'acronis',
          icon: 'acronis',
          to: { name: 'acronis' },
        },
        {
          key: 'domain',
          icon: 'domain',
          to: { name: 'Domains' },
        },
        {
          key: 'ssl',
          icon: 'dummy_ssl_rsnx_oa_migrate',
          to: { name: 'SSL' },
        },
        {
          key: 'license',
          icon: 'soft',
          to: { name: 'soft' },
        },
        {
          key: 'antiddosgcore',
          icon: 'antiddosgcore',
          to: { name: 'antiddos' },
        },
        {
          key: 'rent',
          icon: 'rps',
          to: { name: 'rent' },
        },
      ],
      servicesGridNotAuth: [
        {
          key: 'vps',
          icon: 'vds',
          link: 'https://www.rusonyx.ru/hosting/vps/',
        },
        {
          key: 'clouds',
          icon: 'vdc3',
          link: 'https://www.rusonyx.ru/iaas/',
        },
        {
          key: 'hosting',
          icon: 'vhost',
          link: 'https://www.rusonyx.ru/hosting/',
        },
        {
          key: 'acronis',
          icon: 'acronis',
          link: 'https://www.rusonyx.ru/cloud-backup/',
        },
        {
          key: 'domain',
          icon: 'domain',
          link: 'https://www.rusonyx.ru/domains/register/',
        },
        {
          key: 'ssl',
          icon: 'dummy_ssl_rsnx_oa_migrate',
          link: 'https://www.rusonyx.ru/ssl/',
        },
        {
          key: 'license',
          icon: 'soft',
          link: 'https://www.rusonyx.ru/1c-bitrix/license/',
        },
        {
          key: 'antiddosgcore',
          icon: 'antiddosgcore',
          link: 'https://www.rusonyx.ru/blog/rusonyx/92/?sphrase_id=110009',
        },
        {
          key: 'rent',
          icon: 'rps',
          link: 'https://www.rusonyx.ru/hosting/arenda-servera/',
        },
      ],
    };
  },

  computed: {
    isAuth() {
      return !!localStorage.getItem('token');
    },
    menu() {
      return this.isAuth ? this.servicesGrid : this.servicesGridNotAuth;
    },
  },
  methods: {
    gotoLink(link) {
      return window.open(link, '_blank');
    },
    linkTo() {
      window.open('https://www.rusonyx.ru/blog/rusonyx/');
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "signin": "Вернуться в личный кабинет",
      "read": "Читать блог",
      "text": {
        "Error": "Напишите на электронную почту support@rusonyx.ru  и мы постараемся решить вашу проблему."
      },
      "titles": {
        "Recovery": "Забыли пароль?",
        "AuthSupport": "Забыли почту?",
        "Activation": "Подтверждение регистрации",
        "Error": "К сожалению оплата не прошла",
        "Success": "Оплата прошла успешно!"
      },
      "blog": {
        "title": "Особое преимущество",
        "first": "Приглашаем посетить блог Rusonyx - must-read для владельцев веб-проектов.",
        "second": "Вас ждут выгодные акции, пошаговые инструкции, важная аналитика и авторский взгляд на индустрию.",
        "third": "Мы освещаем главное в мире IT: от подробного рассказа, что такое FTP до собственного видения будущего Интернета.",
        "fourth": "Приятного и полезного чтения!"
      },
      "vps": "VPS Серверы",
      "clouds": "Облачные услуги",
      "hosting": "Виртуальный хостинг",
      "acronis": "Облачный бэкап",
      "domain": "Домены и DNS",
      "ssl": "SSL сертификаты",
      "license": "Лицензии",
      "antiddosgcore": "Защита AntiDDoS",
      "rent": "Выделенный сервер",
      "idprotect": "Защита данных владельца домена",
      "bitrix-license": "Лицензии Bitrix"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.login-page {
  width: 100%;
  //min-height: 100vh;
  padding: 2.5rem 1rem;
  background: var(--thinking-background);
  flexy(center, center);
  flex-direction: column;

  &__content {
    flex: 0 1 550px;
  }

  &__submit {
    min-width: 165px;
  }

  &__logo {
    max-width: 147px;
    margin: 0 auto 2.5rem;
    color: #000000;
  }

  &__card {
    //min-height: min-content;
    //min-height: min-content;
  }

  &__nav {
    &-link {
      color: var(--thinking-text);
      text-decoration: none;

      &.active {
        font-weight: $font-weight-bold;
      }
    }
    &-top {
      flexy(center, center);
      margin-bottom: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-bottom: 2.5rem;
      }

      & ^[0]__nav-link {
        margin: 0 0.5rem;

        +breakpoint(sm-and-up) {
          margin: 0 1.25rem;
        }
      }
    }
    &-bottom {
      flexy(flex-start, center, nowrap, column);
      margin-top: 0.75rem;

      & ^[0]__nav-link {
        margin: 0.75rem;
      }
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 1.5rem;
    color: var(--thinking-text);

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }
}
.card-entry {
  margin-top: 40px;
  max-width: 402px;
  min-width: 360px;
  // box-sizing: border-box;
  height: auto;
  // background: #ffffff;
  // border: 1px solid #dfe1eb;
  // box-sizing: border-box;
  // box-shadow: 0px 9px 45px -20px rgba(14, 42, 87, 0.407015);
  // border-radius: 10px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.card-entry-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;

  span {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: var(--text);
  }
}
.card-entry-block:nth-child(1) {
  margin-top: 0px;
}

.button-wrapper.btn {
  margin: auto;
  margin-top: 40px;

  &_size_big {
    width: 192px;
  }
  &_size_small {
    width: 164px;
  }
}

.text {
  margin-top: 8px;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;

    color: var(--text);
  }
}
.text.log-text {
  text-align: left;
}
.tariff-card {
  margin-top: 1.5rem;
  min-height: 100%;

  &__title {
    text-decoration: none;
    color: inherit;
    //max-width: 15rem;
  }
  &__ip {
    margin-top: 2px;
    +breakpoint(sm-and-up) {
      margin-top: 20px;
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      margin-top: 0.25rem;
    }
  }
  &__note {
    //+breakpoint(sm-and-up) {
    margin-right: 0.75rem;
    //}
  }
  &__more {
    display: block;
    margin-right: -0.5rem;

    +breakpoint(sm-and-up) {
      margin-right: -1rem;
      margin-top: -2px;
    }
  }
  &__activity {
    margin-top: 1.5rem;
    //min-width: 20rem;
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }

    &-actions {
      flexy(flex-start, baseline);
      margin-top: 0.75rem;

      +breakpoint(sm-and-up) {
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
