import { formDataParser } from '@/utils/parsers/formDataParser';
import BillMgrApi from '@/services/api/BillMgrApi';
import MyRsExtApi from '@/services/api/MyRsxExtApi';
import OpenStackApi from '@/services/api/OpenStackApi';
import StorageApi from '@/services/api/StorageApi';
import router from '@/router';
import {
  S3Client,
  ListBucketsCommand,
  ListObjectsV2Command,
  GetBucketPolicyStatusCommand,
  GetBucketPolicyCommand,
  GetPublicAccessBlockCommand,
  PutObjectAclCommand,
  HeadObjectCommand,
  DeleteObjectCommand,
  DeleteBucketCommand,
  GetObjectCommand,
  PutBucketPolicyCommand,
  GetObjectAclCommand,
  CreateBucketCommand,
  PutBucketAclCommand,
} from '@aws-sdk/client-s3';
import { OPObject } from '@/models/OP/OPObgect';

const endpoint = 'https://s3.rusonyxcloud.ru';
const region = 'ru-msk';
const key = ({ state }) => {
  return {
    accessKeyId: state.defaultS3Key.access,
    secretAccessKey: state.defaultS3Key.secret,
  };
};
const configS3 = ({ state }) => {
  return {
    region: region,
    endpoint: endpoint,
    credentials: key({ state }),
  };
};

export const clientS3test = config => new S3Client(config);
const request = {
  servers: '/compute/v2.1/servers/detail',
  floatingips: '/network/v2.0/floatingips',
  flavors: '/compute/v2.1/flavors/detail',
  networks: '/network/v2.0/networks',
  images: '/image/v2/images',
  routers: '/network/v2.0/routers',
  subnets: '/network/v2.0/subnets',
  loadbalancers: '/lbaas/v2/loadbalancers',
  flavorprofiles: '/lbaas/v2/flavorprofiles',
  healthmonitors: '/lbaas/v2/healthmonitors',
  listeners: '/lbaas/v2/listeners',
  pools: '/lbaas/v2/pools',
  members: '/lbaas/v2/members',
  projects: '/identity/v3/auth/projects/',
  volumes: '/volume/v3/volumes/detail',
  ports: '/network/v2.0/ports',
  // storage: `swift/v1/AUTH_${project}/?format=json`,
  secrets: '/secret/v1/secrets',
  containers: '/secret/v1/containers',
  firewalls: '/network/v2.0/security-groups',
  rules: '/network/v2.0/security-group-rules',
  quotas: '          // console.log(data);\n', //WTF
};
// const dns_nameservers = ['8.8.8.8', '8.8.4.4'];
export const ACTIONS = {
  fetchList({ commit }, payload = {}) {
    // commit('SET_IS_LOADING', true);
    if (!payload.silent) commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/vdc3';
      const params = {
        metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          // console.log(data.elem);
          if (!payload.status) commit('SET_LIST', data.elem);
          if (!payload.status) commit('SET_TOOLS', data.metadata.toolbtndata);
          if (payload.status) {
            data.elem
              .filter(x => x.status === '5' || '1')
              .forEach(x => {
                // console.log(x.id, x.status);
                const payload = {
                  id: x.id,
                  status: +x.status,
                };
                // console.log(payload);
                commit('SET_STATUS', payload);
              });
          }
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!payload.silent) commit('SET_IS_LOADING', false);
          // commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchExpenseList({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const params = {
        func: 'expense',
        clickstat: 'yes',
      };
      BillMgrApi.get('billmgr', { params })
        .then(data => {
          commit('SET_EXPENSE', data.elem || [], payload);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateMainList({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = '/api2/billmgr/vdc3';
      const params = {
        metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('UPDATE_LIST', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  setCurrent: async (context, id) => {
    try {
      await context.commit('SET_CURRENT', id);
      const tariff = await context.getters['GET_CURRENT_SERVER'];
      return tariff;
    } catch (err) {
      console.error(err);
    }
  },
  setCurrentServer: async (context, payload) => {
    try {
      await context.commit('setCurrentServer', payload);
      const server = await context.getters['getCurrentServer'];
      return server;
    } catch (err) {
      console.error(err);
    }
  },
  resetCurrentServer({ commit }) {
    // console.log('resetCurrentServer');
    commit('resetCurrentServer');
  },
  fetchDetail({ commit, dispatch }, id) {
    // console.log(id);
    const params = {
      elid: id,
      func: 'vdc3.edit',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(async data => {
          const { customfields, slist, model } = data;
          const details = await dispatch('fetchTariffCalc', model);
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'info',
            value: {
              customfields,
              slist,
              model,
              details: details.model.cost_details,
            },
            id,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchHistory({ commit }, id) {
    const params = {
      func: 'service.history',
      elid: id,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('', { params })
        .then(data => {
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'history',
            value: data.elem,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchTariffCalc(_ctx, model) {
    const params = {
      ...model,
      func: 'pricelist.calculate',
      auth: localStorage.getItem('token'),
      out: 'bjson',
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  updateTariff(_ctx, model) {
    const params = {
      ...model,
      func: 'vdc3.edit',
      auth: localStorage.getItem('token'),
      out: 'bjson',
      clicked_button: 'on',
      sok: 'ok',
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchBillMgrToolAction(_ctx, payload) {
    const params = {
      ...payload,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  fetchConfig(_ctx, err = null) {
    return MyRsExtApi.get('stack/params');
  },
  sendOrder(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      sok: 'ok',
      func: 'clientticket.edit',
      out: 'bjson',
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  getOpenStackApiKey(_ctx, id) {
    if (!id) throw new Error('Missed required prop: "elid"');
    return new Promise((resolve, reject) => {
      BillMgrApi.get(`/extapi-os/gettoken?&elid=${id}`)
        .then(data => {
          localStorage.setItem('stack', data.token);
          resolve(data);
        })
        .catch(e => {
          // localStorage.setItem('token', '');
          localStorage.removeItem('token');
          localStorage.removeItem('stack');
          router.push({ name: 'Auth' }).catch(() => {});
          // this.$router.push({ name: 'Start' }).catch(() => {});
          reject(e);
        });
    });
  },
  validateOpenStackApiKey(_ctx, id) {
    if (!id) throw new Error('Missed required prop: "elid"');
    return new Promise((resolve, reject) => {
      BillMgrApi.get(`/extapi-os/validatetoken?&elid=${id}&token=${localStorage.getItem('stack')}`)
        .then(data => {
          // console.log(data.ok);
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  fetchOpenStackRequest({ commit }, action) {
    return new Promise((resolve, reject) => {
      OpenStackApi.get(request[action])
        .then(response => {
          const rawData = response.data[action];
          commit(`${action}`, { payload: rawData, action: action });
          if (action === 'servers') {
            const tags = rawData.map(x => x.tags);
            commit('tags', Array.from(new Set(tags.flat())));
          }
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  async getObjectACL({ commit, state }, payload) {
    const input = {
      Bucket: payload.bucket,
      Key: payload.name,
    };
    const command = new GetObjectAclCommand(input);
    const response = await clientS3test(configS3({ state })).send(command);
    commit('updateObject', {
      prop: 'grants',
      value: response.Grants,
      storage: payload.bucket,
      object: payload.name,
    });
    commit('updateObject', {
      prop: 'owner',
      value: response.Owner,
      storage: payload.bucket,
      object: payload.name,
    });
    return response;
  },
  async getObjectHead({ commit, state }, payload) {
    const input = {
      Bucket: payload.bucket,
      Key: payload.name,
    };
    console.log(input, state);
    const command = new HeadObjectCommand(input);
    console.log(command);
    const middleware = await clientS3test(configS3({ state }));
    console.log(middleware);

    const response = await middleware
      .send(command)
      .then(r => {
        console.log(r);
        return r;
      })
      .catch(async e => await middleware.send(command));
    // const response = await clientS3test(configS3({ state })).send(command);
    console.log(response);
    commit('updateObject', {
      prop: 'metadata',
      value: response.Metadata,
      storage: payload.bucket,
      object: payload.name,
    });
    commit('updateObject', {
      prop: 'type',
      value: response.ContentType,
      storage: payload.bucket,
      object: payload.name,
    });
    console.log(response);
    return response;
  },
  async deleteS3Bucket({ commit, state }, name) {
    const input = {
      Bucket: name,
    };
    const command = new DeleteBucketCommand(input);
    return await clientS3test(configS3({ state })).send(command);
  },
  async deleteS3Object({ commit, state }, payload) {
    const input = {
      Bucket: payload.bucket,
      Key: payload.name,
    };
    const command = new DeleteObjectCommand(input);
    return await clientS3test(configS3({ state })).send(command);
  },
  async createS3Bucket({ commit, state }, { name: name, type: type }) {
    const input = {
      ACL: type,
      Bucket: name,
      CreateBucketConfiguration: {
        LocationConstraint: region,
      },
    };
    const command = new CreateBucketCommand(input);
    return await clientS3test(configS3({ state })).send(command);
  },
  async setACLObject({ commit, state }, { name: name, obj: obj, policy: policy }) {
    const input = {
      Bucket: name,
      Key: obj,
      ACL: policy,
    };
    const command = new PutObjectAclCommand(input);
    return await clientS3test(configS3({ state })).send(command);
  },
  async setACLS3Bucket({ commit, state }, { name: name, policy: policy }) {
    const input = {
      Bucket: name,
      ACL: policy,
    };
    const command = new PutBucketAclCommand(input);
    return await clientS3test(configS3({ state })).send(command);
  },
  async getObject({ commit, state }, { bucket: bucket, name: name }) {
    const input = {
      Bucket: bucket,
      Key: name,
    };
    const command = new GetObjectCommand(input);
    return await clientS3test(configS3({ state })).send(command);
  },
  async fetchCurrentStorages({ commit, state }, bucket) {
    const input = {
      Bucket: bucket,
    };
    const listCommand = new ListObjectsV2Command(input);
    const bucketPolicyCommandStatus = new GetBucketPolicyStatusCommand(input);
    try {
      const responseListCommand = await clientS3test(configS3({ state })).send(listCommand);
      const responsePolicyCommandStatus = await clientS3test(configS3({ state })).send(
        bucketPolicyCommandStatus
      );
      const { PolicyStatus } = responsePolicyCommandStatus;
      commit('updateStorage', {
        prop: 'policy',
        value: PolicyStatus,
        id: bucket,
      });
      const { Contents, KeyCount } = responseListCommand;
      commit('updateStorage', {
        prop: 'count',
        value: KeyCount,
        id: bucket,
      });
      if (Contents) {
        commit('updateStorage', {
          prop: 'objects',
          value: Contents.map(i => new OPObject(i, 'objects')),
          id: bucket,
        });
        commit('object', { payload: Contents, id: bucket });
      }
    } catch (err) {
      console.error(err);
    }
  },
  async fetchProjectStorages({ commit, state }) {
    const allBucketCommand = new ListBucketsCommand({});
    const { Buckets } = await clientS3test(configS3({ state })).send(allBucketCommand);
    if (Buckets) {
      commit('storages', { payload: Buckets, action: 'storages' });
      await Promise.all(
        Buckets.map(async x => {
          const input = {
            Bucket: x.Name,
          };
          const listCommand = new ListObjectsV2Command(input);
          const bucketPolicyCommandStatus = new GetBucketPolicyStatusCommand(input);

          try {
            const responseListCommand = await clientS3test(configS3({ state })).send(listCommand);
            const responsePolicyCommandStatus = await clientS3test(configS3({ state })).send(
              bucketPolicyCommandStatus
            );
            const { PolicyStatus } = responsePolicyCommandStatus;
            commit('updateStorage', {
              prop: 'policy',
              value: PolicyStatus,
              id: x.Name,
            });
            const { Contents, KeyCount } = responseListCommand;
            commit('updateStorage', {
              prop: 'count',
              value: KeyCount,
              id: x.Name,
            });
            if (Contents) {
              commit('updateStorage', {
                prop: 'objects',
                value: Contents.map(i => new OPObject(i, 'objects')),
                id: x.Name,
              });
              commit('newObject', { payload: Contents, id: x.Name });
            }
          } catch (err) {
            console.error(err);
          }
        })
      );
    }
  },
  // getObjectAcl({commit}, payload){
  //
  // },
  // fetchStorages({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     if (payload.type === 'get') {
  //       // OpenStackApi[payload.type](`swift/v1/AUTH_${payload.project}/${payload.name}?format=json`)
  //       StorageApi[payload.type](`${payload.project}/${payload.name}?format=json`)
  //         .then(response => {
  //           // console.log(response.headers);
  //           commit('object', { payload: response.data, id: payload.name });
  //           commit('updateTypeStorage', {
  //             prop: 'x-container-read',
  //             value: response.headers['x-container-read'],
  //             id: payload.name,
  //           });
  //           resolve(response);
  //         })
  //         .catch(e => reject(e))
  //         .finally(() => {});
  //     } else {
  //       // OpenStackApi[payload.type](`swift/v1/AUTH_${payload.project}/${payload.name}?format=json`)
  //       StorageApi[payload.type](`${payload.project}/${payload.name}?format=json`)
  //         .then(response => {
  //           // console.log(response.headers);
  //           // console.log(response);
  //           commit('updateStorage', {
  //             prop: 'headers',
  //             value: response.headers,
  //             id: payload.name,
  //           });
  //
  //           resolve(response);
  //         })
  //         .catch(e => reject(e))
  //         .finally(() => {});
  //     }
  //   });
  // },
  // fetchObjects({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     StorageApi.get(`${payload.project}/${payload.storage}/${payload.object}?format=json`)
  //       .then(response => {
  //         // console.log(response);
  //         commit('updateObject', {
  //           prop: 'headers',
  //           value: response.headers,
  //           storage: payload.storage,
  //           object: payload.object,
  //         });
  //         resolve(response);
  //       })
  //       .catch(e => reject(e))
  //       .finally(() => {});
  //   });
  // },
  // copyObjects({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     StorageApi.copy(`${payload.project}/${payload.storage}/${payload.object}?format=json`, _, {
  //       headers: {
  //         Destination: payload.destination,
  //       },
  //     })
  //       .then(response => {
  //         console.log(response);
  //         // commit('updateObject', {
  //         //   prop: 'headers',
  //         //   value: response.headers,
  //         //   storage: payload.storage,
  //         //   object: payload.object,
  //         // });
  //         resolve(response);
  //       })
  //       .catch(e => reject(e))
  //       .finally(() => {});
  //   });
  // },

  fetchPools({ commit }, id) {
    return new Promise((resolve, reject) => {
      let url = `/lbaas/v2/pools/${id}`;
      OpenStackApi.get(url)
        .then(response => {
          // console.log(response, 'response');
          commit('updatePool', response.data['pool']);
          resolve(response.data['pool']);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  fetchOpenStackRequestFirewalls({ commit }, action) {
    return new Promise((resolve, reject) => {
      OpenStackApi.get(request[action])
        .then(response => {
          commit(`${action}`, { payload: response.data['security_groups'], action: action });
          resolve(response.data['security_groups']);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  fetchOpenStackRequestRules({ commit }, action) {
    return new Promise((resolve, reject) => {
      OpenStackApi.get(request[action])
        .then(response => {
          commit(`${action}`, { payload: response.data['security_group_rules'], action: action });
          resolve(response.data['security_group_rules']);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  fetchSnapshotsServer({ commit }) {
    let url = '/volume/v3/snapshots/detail';
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(response => {
          commit('snapshots', { payload: response.data.snapshots, action: 'snapshots' });
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  fetchPrivateImages({ commit }) {
    // let url = '/image/v2/images?&visibility=private';
    let url = '/image/v2/images';
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(response => {
          const usersImages = response.data.images.filter(x => x.visibility !== 'public');
          commit('privateImages', { payload: usersImages, action: 'privateImages' });
          // commit('privateImages', { payload: response.data.images, action: 'privateImages' });
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  fetchQuotasServer({ commit }, id) {
    let url = `/compute/v2.1/os-quota-sets/${id}/detail`;
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(response => {
          // console.log(response.data);
          commit('quotasServer', response.data);
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  fetchOsKeypair({ commit }) {
    let url = '/compute/v2.1/os-keypairs';
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(response => {
          commit('keypairs', response.data);
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  fetchQuotasNetwork({ commit }, id) {
    let url = `/network/v2.0/quotas/${id}/details`;
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(response => {
          commit('quotasNetwork', response.data);
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  fetchQuotasVolume({ commit }) {
    let url = '/volume/v3/limits';
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(response => {
          commit('quotasVolume', response.data);
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  fetchOpenStack({ commit }, url) {
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(response => {
          // console.log(data);
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  updateDiskServers({ commit }, payload) {
    const action = payload[2];
    payload[0].filter(i => {
      payload[1].filter(disk => {
        const instance = i['os-extended-volumes:volumes_attached'];
        instance.forEach(x => {
          if (x.id === disk.id) {
            const payload = [i, disk, action];
            commit('updateServer', payload);
          }
        });
      });
    });
  },
  updateFlavorsServers({ commit }, payload) {
    const action = payload[2];
    payload[0].filter(i => {
      payload[1].filter(flavors => {
        if (i.flavor.id === flavors.id) {
          const payload = [i, flavors, action];
          commit('updateServer', payload);
        }
      });
    });
  },
  updateServer({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    let url = `compute/v2.1/servers/${payload.id}`;
    const action = {
      server: {
        [payload.type]: payload.name,
      },
    };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, action)
        .then(response => {
          commit('updateServerName', {
            id: payload.id,
            type: payload.type,
            name: response.data.server[payload.type],
          });
          resolve(response.data.server[payload.type] ? response.data.server[payload.type] : 'ok');
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateFlavor({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    let url = `compute/v2.1/flavors/${payload.id}`;
    const action = {
      flavor: {
        [payload.type]: payload.name,
      },
    };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, action)
        .then(response => {
          commit('updateFlavor', {
            id: payload.id,
            type: payload.type,
            name: response.data.flavor[payload.type],
          });
          resolve(response.data.flavor[payload.type] ? response.data.flavor[payload.type] : 'ok');
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateNetwork({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    let url = `/network/v2.0/networks/${payload.id}`;
    const action = { network: payload.network };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: payload.id,
            item: payload.item,
            state: response.data.network[payload.item],
          };
          commit('updateNetwork', params);
          resolve(response.data.network[payload.item]);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateBalancer({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    const balancer = payload.id;
    let url = `/lbaas/v2/loadbalancers/${balancer}`;
    const action = { loadbalancer: payload.loadbalancer };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: balancer,
          };
          Object.keys(payload.loadbalancer).forEach(key => {
            params[key] = response.data.loadbalancer[key];
          });
          commit('updateBalancers', params);
          resolve(response.data.loadbalancer);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  checkBalancerStatus({ commit }, payload) {
    let url = `/lbaas/v2/loadbalancers/${payload.id}`;
    const id = payload.id;
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(async ({ data }) => {
          const rawDate = data.loadbalancer;
          const status = data.loadbalancer.provisioning_status;
          const state = data.loadbalancer.operating_status;
          await commit('updateBalancerStatus', { id, status, state, type: 'balancers' });
          resolve({ data: data.loadbalancer });
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {});
    });
  },
  checkPoolStatus({ commit }, payload) {
    let url = `/lbaas/v2/pools/${payload.id}`;
    const id = payload.id;
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(async ({ data }) => {
          const status = data.pool.provisioning_status;
          const state = data.pool.operating_status;
          await commit('updatePoolStatus', { id, status, state, type: 'pools' });
          resolve({ data: data.pool });
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {});
    });
  },
  checkMemberStatus({ commit }, payload) {
    const rule = payload.ruleId;
    let url = `/lbaas/v2/pools/${rule}/members/${payload.memberId}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(async ({ data }) => {
          const params = {
            ruleId: rule,
            state: data.member,
          };
          commit('updateRuleMember', params);
          resolve(data.member);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {});
    });
  },
  renameNetwork({ commit }, payload) {
    let url = `/network/v2.0/networks/${payload.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          commit('updateNetworkDescription', {
            id: payload.id,
            item: payload.item,
            state: data.network[payload.item],
          });
          resolve(data.network);
        })
        .catch(e => reject(e));
    });
  },
  updateRuleMember({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    const rule = payload.ruleId;
    let url = `/lbaas/v2/pools/${rule}/members/${payload.memberId}`;
    const action = { member: payload.member };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            ruleId: rule,
            state: response.data.member,
          };
          commit('updateRuleMember', params);
          resolve(response.data.member);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateHealthMonitor({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    const hm = payload.id;
    let url = `/lbaas/v2/healthmonitors/${hm}`;
    const action = { healthmonitor: payload.healthmonitor };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: hm,
          };
          Object.keys(payload.healthmonitor).forEach(key => {
            params[key] = response.data.healthmonitor[key];
          });
          commit('updateHealthMonitor', params);
          resolve(response.data.healthmonitor);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateListener({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    const listener = payload.id;
    let url = `/lbaas/v2/listeners/${listener}`;
    const action = { listener: payload.listener };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: listener,
          };
          Object.keys(payload.listener).forEach(key => {
            params[key] = response.data.listener[key];
          });

          commit('updateListner', params);
          resolve(response.data.listener);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  deleteListener({ commit }, id) {
    commit('SET_IS_LOADING', true);
    let url = `/lbaas/v2/listeners/${id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url)
        .then(response => {
          resolve(response.status);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  addListener({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    let url = '/lbaas/v2/listeners';
    const action = { listener: payload };
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, JSON.stringify(action))
        .then(response => {
          resolve(response.data.listener);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  addPool({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    let url = '/lbaas/v2/pools';
    const action = { pool: payload };
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, JSON.stringify(action))
        .then(response => {
          resolve(response.data.pool);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updatePool({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    const pool = payload.id;
    let url = `/lbaas/v2/pools/${pool}`;
    const action = { pool: payload.pool };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: pool,
          };
          Object.keys(payload.pool).forEach(key => {
            params[key] = response.data.pool[key];
          });
          commit('updatePool', params);
          resolve(response.data.pool);
        })
        .catch(e => reject(e.response.data.badRequest.message))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  deletePool({ commit }, id) {
    commit('SET_IS_LOADING', true);
    let url = `/lbaas/v2/pools/${id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url)
        .then(response => {
          resolve(response.status);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  addHealthmonitor({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    let url = '/lbaas/v2/healthmonitors';
    const action = { healthmonitor: payload };
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, JSON.stringify(action))
        .then(response => {
          // resolve(response.data.healthmonitor);
          setTimeout(() => resolve(response.data.healthmonitor), 3000);
          // pool
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateRule({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    const pool = payload.id;
    let url = `/lbaas/v2/pools/${pool}`;
    const action = { pool: payload.pool };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: pool,
            lb_algorithm: response.data.pool.lb_algorithm,
            session_persistence: response.data.pool.session_persistence,
          };
          commit('updatePool', params);
          resolve(response.data.pool);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchCurrentNetwork({ commit }, id) {
    commit('SET_IS_LOADING', true);
    let url = `/network/v2.0/networks/${id}`;
    // const action = { network: payload.network };
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(response => {
          // console.log(response);
          const params = {
            network_id: response.data.network.id,
            subnet_id: response.data.network.subnets[0],
          };
          // console.log(params);
          resolve(params);
          // commit('updateNetwork', params);
          // resolve(response.data.network[payload.item]);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  updateRouter({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    let url = `/network/v2.0/routers/${payload.id}`;
    const action = { router: payload.router };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: payload.id,
            item: payload.item,
            state: response.data.router[payload.item],
          };
          commit('updateRouter', params);
          resolve(response.data.router[payload.item]);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  renameRouter({ commit }, payload) {
    let url = `/network/v2.0/routers/${payload.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          const params = {
            id: payload.id,
            item: payload.item,
            state: data.router[payload.item],
          };
          commit('updateRouterName', params);
          resolve(data.router);
        })
        .catch(e => reject(e));
    });
  },
  updateFirewall({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    let url = `/network/v2.0/security-groups/${payload.id}`;
    const action = { security_group: payload.firewall };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: payload.id,
            item: payload.item,
            // state: response.data.router[payload.item],
          };
          commit('updateFirewall', params);
          resolve(response.data['security_group'][payload.item]);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  renameFirewall({ commit }, payload) {
    let url = `/network/v2.0/security-groups/${payload.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          commit('updateFirewallDescription', {
            id: payload.id,
            item: payload.item,
            state: data.security_group[payload.item],
          });
          resolve(data.security_group);
        })
        .catch(e => reject(e));
    });
  },
  updateSubnet({ commit }, payload) {
    commit('SET_IS_LOADING', true);
    let url = `/network/v2.0/subnets/${payload.id}`;
    const action = { subnet: payload.subnet };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: payload.id,
            item: payload.item,
            state: response.data.subnet[payload.item],
          };
          commit('updateSubnets', params);
          resolve(response.data.subnet[payload.item]);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  renameSubnet({ commit }, payload) {
    let url = `/network/v2.0/subnets/${payload.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          commit('updateSubnetDescription', {
            id: payload.id,
            item: payload.item,
            state: data.subnet[payload.item],
          });
          resolve(data.subnet);
        })
        .catch(e => reject(e));
    });
  },
  updateSubnetDns({ commit }, payload) {
    console.log(payload);
    let url = `/network/v2.0/subnets/${payload.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          console.log(data.subnet[payload.item]);
          commit('updateSubnetDns', {
            id: payload.id,
            item: payload.item,
            state: data.subnet[payload.item],
          });
          resolve(data.subnet);
        })
        .catch(e => reject(e));
    });
  },
  updatePort({ commit }, payload) {
    // console.log(payload);
    let url = `/network/v2.0/ports/${payload.id}`;
    const action = { port: payload.port };
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(action))
        .then(response => {
          const params = {
            id: payload.id,
            item: payload.item, // меняемое свойство
            state: response.data.port[payload.item], //значение изменяемого свойства
          };
          if (response) commit('updatePorts', params);
          resolve(response.data.port[payload.item]);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  renamePort({ commit }, payload) {
    let url = `/network/v2.0/ports/${payload.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          commit('updatePortDescription', {
            id: payload.id,
            item: payload.item,
            state: data.port[payload.item],
          });
          resolve(data.port);
        })
        .catch(e => reject(e));
    });
  },
  updateNetworksServers({ commit }, payload) {
    const action = payload[2];
    payload[0].filter(i => {
      payload[1].filter(network => {
        if (Object.keys(i.addresses)[0] === network.name) {
          const payload = [i, network, action];
          commit('updateServer', payload);
        }
      });
    });
  },
  setServerAction({ commit }, { id, method = 'post', params = {} }) {
    if (!id) throw new Error('Missed required prop: "id"');
    let url = `compute/v2.1/servers/${id}/action`;
    return new Promise((resolve, reject) => {
      OpenStackApi[method](url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  setTags({ commit }, { id, params = {} }) {
    // console.log(params);
    if (!id) throw new Error('Missed required prop: "id"');
    let url = `compute/v2.1/servers/${id}/tags`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, params)
        .then(data => {
          // console.log(data);
          resolve(data.status);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  getServerLog({ commit }, { id, method = 'post', params = {} }) {
    if (!id) throw new Error('Missed required prop: "id"');
    let url = `compute/v2.1/servers/${id}/action`;
    return new Promise((resolve, reject) => {
      OpenStackApi[method](url, params)
        .then(data => {
          resolve(data.data);
        })
        .catch(e => reject(e))
        .finally(() => {});
    });
  },
  getPoolMembers({ commit }, { id, method = 'get', params = {} }) {
    commit('setUpdateMembers', true);
    if (!id) throw new Error('Missed required prop: "id"');
    let url = `/lbaas/v2/pools/${id}/members`;
    return new Promise((resolve, reject) => {
      OpenStackApi[method](url, params)
        .then(data => {
          commit('setMembers', { id: id, data: data.data.members });
          resolve(data.data.members);
        })
        .catch(e => reject(e))
        .finally(() => {
          commit('setUpdateMembers', false);
        });
    });
  },
  createSnapshot({ commit }, { method = 'post', params = {} }) {
    let url = 'volume/v3/snapshots';
    return new Promise((resolve, reject) => {
      OpenStackApi[method](url, params)
        .then(({ data }) => {
          resolve(data.snapshot);
        })
        .catch(e => reject(e));
    });
  },

  RenameSnapshot({ commit }, payload) {
    let url = `volume/v3/snapshots/${payload.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          commit('updateSnapshotName', {
            id: payload.id,
            type: payload.type,
            name: data.snapshot[payload.type],
          });
          resolve(data.snapshot);
        })
        .catch(e => reject(e));
    });
  },

  createImageFromDisk({ commit }, payload) {
    let url = `volume/v3/volumes/${payload.id}/action`;
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },

  createOsKeyPair({ commit }, payload) {
    let url = 'compute/v2.1/os-keypairs';
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  createVncConsole({ commit }, payload) {
    let url = `compute/v2.1/servers/${payload.server}/remote-consoles`;
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, JSON.stringify(payload.vnc))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  getPort({ commit }, id) {
    commit('SET_IS_LOADING', true);
    let url = 'network/v2.0/ports?sort_dir=asc&sort_key=name';
    const params = {
      device_id: id,
    };
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url, { params })
        .then(({ data }) => {
          commit('currentPort', data);
          resolve(data);
        })
        .catch(e => reject(e))
        .finally(() => commit('SET_IS_LOADING', false));
    });
  },
  getTypesOfDisk({ commit }) {
    // commit('SET_IS_LOADING', true);
    let url = 'volume/v3/types';
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url)
        .then(({ data }) => {
          commit('setTypesOfDisks', data.volume_types);
          resolve(data);
        })
        .catch(e => reject(e));
      // .finally(() => commit('SET_IS_LOADING', false));
    });
  },
  addFiles({ commit }, payload) {
    commit('SET_FILES', payload);
  },
  resetFiles({ commit }) {
    commit('RESET_FILES');
  },
  deleteFiles({ commit }, payload) {
    commit('DELETE_FILES', payload);
  },
  createDisk({ commit }, params = {}) {
    let url = 'volume/v3/volumes';
    return new Promise((resolve, reject) => {
      const payload = {
        volume: params,
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  createNetwork({ commit }, params = {}) {
    let url = '/network/v2.0/networks';
    return new Promise((resolve, reject) => {
      const payload = {
        network: params,
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  createServer({ commit }, params = {}) {
    let url = '/compute/v2.1/servers';
    return new Promise((resolve, reject) => {
      const payload = {
        server: params,
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  createPublicNetwork(_ctx, payload) {
    const params = {
      elid: payload.id,
      prefix: payload.prefix,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/extapi-os/reservenetwork', { params })
        .then(data => {
          // console.log(data);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  getPublicNetworkPrefix({ commit }, id) {
    const params = {
      elid: id,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/extapi-os/getprefixes', { params })
        .then(data => {
          // console.log(data);
          commit('setPrefixes', data.prefixes);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  getDnsNames({ commit }, id) {
    const params = {
      elid: id,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/extapi-os/getdefaultns', { params })
        .then(data => {
          if (data && data.result === 'ok') {
            commit('setDnsNames', data.nameservers);
            resolve(data);
          } else
            reject('Неверные параметры запроса DNS имен. Пожалуйста обратитесь в техподдержку.');
        })
        .catch(e => reject(e));
    });
  },
  deletePublicNetwork(_ctx, params) {
    const payload = {
      elid: params.id,
      network_id: params.network,
      auth: localStorage.getItem('token'),
    };
    if (params.prefix) payload.prefix = params.prefix;
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/extapi-os/releasenetwork', payload)
        .then(data => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  getPrice({ commit }, id) {
    const params = {
      elid: id,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/extapi-os/getprice', { params })
        .then(data => {
          if (data.result === 'ok') {
            commit('setPrice', data.price);
            commit('setPriceFlavor', data.flavor);
            resolve(data);
          }
        })
        .catch(e => reject(e));
    });
  },
  setS3StoragesKeyDescription({ commit }, payload) {
    const params = {
      access_key: payload.key,
      description: payload.description,
      elid: payload.id,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      // BillMgrApi.post('/extapi-os/ec2', JSON.stringify(params))
      // BillMgrApi.post('/extapi-os-lab/ec2', params)
      BillMgrApi.post('/extapi-os/ec2', params)
        .then(data => {
          console.log(data);
          if (data?.result === 'ok') {
            //   commit('setS3Keys', data.ec2);
            resolve(data);
          } else {
            //   commit('setS3Keys', []);
            resolve('error');
          }
        })
        .catch(e => reject(e));
    });
  },
  getS3StoragesKeys({ commit }, id) {
    const params = {
      elid: id,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/extapi-os/ec2', { params })
        .then(data => {
          // console.log(data);
          if (data.result === 'ok' && data.ec2) {
            commit('setS3Keys', data.ec2);
            commit('setS3KeyState', false);
            resolve(data);
          } else {
            commit('setS3Keys', []);
            commit('setS3KeyState', true);
            resolve('nokey');
          }
        })
        .catch(e => reject(e));
    });
  },
  setS3KeyDefault({ commit }, payload) {
    console.log(payload);
    commit('setS3KeyDefault', payload);
    commit('setS3KeyState', false);
  },
  deleteS3StoragesKey({ commit }, { id, key }) {
    const params = {
      elid: id,
      access_key: key,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.delete('/extapi-os/ec2', { params })
        .then(data => {
          // console.log(data);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  createS3StoragesKey({ commit, dispatch, state }, id) {
    const params = {
      elid: id,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.put('/extapi-os/ec2', params)
        .then(data => {
          if (state.noS3Key) {
            // console.log(data);
          }
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  getQuotas({ commit }, id) {
    const params = {
      elid: id,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/extapi-os/getquota', { params })
        .then(data => {
          if (data.result === 'ok') {
            commit('getBmQuotas', data.quota_limits);
            resolve(data);
          }
        })
        .catch(e => reject(e));
    });
  },
  setQuotas({ commit }, params) {
    const payload = {
      elid: params.id,
      auth: localStorage.getItem('token'),
      newquota: { ...params.quota },
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/extapi-os/setprojectquota', JSON.stringify(payload))
        .then(data => {
          if (data.result === 'ok') {
            // commit('getBmQuotas', data.quota_limits);
            resolve(data);
          }
        })
        .catch(e => reject(e));
    });
  },
  createRouterWithNetwork(_ctx, id) {
    const params = {
      elid: id,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/extapi-os/allocateprivatenetwork', { params })
        .then(data => {
          // console.log('createRouterWithNetwork', data);
          resolve(data);
        })
        .catch(e => {
          // console.log('111111111111111111111', e);
          reject(e);
        });
    });
  },
  createOtherFlavor({ commit }, params = {}) {
    let url = '/compute/v2.1/flavors';
    return new Promise((resolve, reject) => {
      const payload = {
        ...params,
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e.response));
    });
  },
  isLocalDisk({ commit }, payload) {
    const params = {
      elid: payload.id,
      instance_id: payload.uuid,
      auth: localStorage.getItem('token'),
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/extapi-os/existslocaldisk', { params })
        .then(data => {
          const info = {
            id: payload.uuid,
            local: data.existslocaldisk,
          };
          commit('SET_LOCAL_DISK', info);
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  createSubnet({ commit }, params = {}) {
    // console.log(params.allocation_pools);
    let url = '/network/v2.0/subnets';
    return new Promise((resolve, reject) => {
      const payload = {
        name: params.subnet,
        cidr: params.subnet,
        ip_version: 4,
        // allocation_pools: params.allocation_pools ? params.allocation_pools : null,
        network_id: params.id,
        gateway_ip: params.gateway ? params.gateway : null,
        enable_dhcp: params.dhcp === 'on',
      };
      if (params.dhcp === 'on') payload.dns_nameservers = params.dnsNames;
      params.allocation_pools ? (params.allocation_pools = params.allocation_pools) : null;
      // console.log(payload);
      OpenStackApi.post(url, JSON.stringify({ subnet: payload }))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  createPort({ commit }, params = {}) {
    let url = 'network/v2.0/ports';
    return new Promise((resolve, reject) => {
      const payload = {
        ...params,
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e.response));
    });
  },
  createRule({ commit }, params = {}) {
    let url = 'network/v2.0/security-group-rules';
    return new Promise((resolve, reject) => {
      const payload = {
        ...params,
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e.response));
    });
  },
  createSecret({ commit }, params = {}) {
    let url = 'secret/v1/secrets';
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e.response));
    });
  },
  getPayloadSecret({ commit }, id) {
    let url = `secret/v1/secrets/${id}/payload`;
    return new Promise((resolve, reject) => {
      OpenStackApi.get(url, {
        headers: {
          Accept: 'text/plain',
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e.response));
    });
  },
  createContainer({ commit }, params = {}) {
    let url = 'secret/v1/containers';
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e.response));
    });
  },
  createBalancer({ commit }, params = {}) {
    let url = 'lbaas/v2/loadbalancers';
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e.response));
    });
  },
  createFloatIp({ commit }, params = {}) {
    let url = 'network/v2.0/floatingips';
    return new Promise((resolve, reject) => {
      const payload = {
        ...params,
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          // this.showError(e.response.data.NeutronError.message);
          reject(e.response);
          // reject(e.response.data.NeutronError.message);
        });
    });
  },
  createPortToServer({ commit }, params = {}) {
    let url = `/compute/v2.1/servers/${params.device_id}/os-interface`;
    return new Promise((resolve, reject) => {
      const payload = {
        interfaceAttachment: {
          port_id: params.payload,
        },
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e.response));
    });
  },
  addRouterInterface({ commit }, params = {}) {
    let url = `/network/v2.0/routers/${params.router_id}/add_router_interface`;
    return new Promise((resolve, reject) => {
      const payload = {
        port_id: params.port_id,
      };
      OpenStackApi.put(url, JSON.stringify(payload))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e.response));
    });
  },
  createImage({ commit }, params = {}) {
    let url = 'image/v2/images';
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, JSON.stringify(params))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  createRouter({ commit }, params = {}) {
    let url = '/network/v2.0/routers';
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, JSON.stringify(params))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  createFirewall({ commit }, params = {}) {
    let url = '/network/v2.0/security-groups';
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, JSON.stringify(params))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  renameImage({ commit }, params = []) {
    // console.log(params);
    const id = params.find(x => x.id).id;
    // console.log(id);
    params.pop();
    let url = `image/v2/images/${id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.patch(url, JSON.stringify(params), {
        headers: {
          'Content-Type': 'application/openstack-images-v2.1-json-patch',
        },
      })
        .then(({ data }) => {
          // console.log(data);
          // commit('updateCurrentImage', { id, data: data, type: 'privateImages' });
          commit('updateCurrentImage', { id, data: data });
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  updateTagImage({ commit }, params = {}) {
    // console.log(params);
    const id = params.id;
    const method = params.method;
    const tags = params.tag;
    let url = `image/v2/images/${params.id}/tags/${params.tag}`;
    return new Promise((resolve, reject) => {
      OpenStackApi[params.method](url)
        .then(({ status }) => {
          commit('updateImageTag', { id, tags, method, type: 'privateImages' });
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },

  resizeDisk({ commit }, params) {
    let url = `/volume/v3/volumes/${params.disk}/action`;
    return new Promise((resolve, reject) => {
      const payload = {
        'os-extend': {
          new_size: params.size,
        },
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(data => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  setBootableDisk({ commit }, params) {
    let url = `/volume/v3/volumes/${params.disk}/action`;
    return new Promise((resolve, reject) => {
      const payload = {
        'os-set_bootable': {
          bootable: params.bootable,
        },
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(data => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  renameDisk({ commit }, payload) {
    let url = `volume/v3/volumes/${payload.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          commit('updateDiskName', {
            id: payload.id,
            type: payload.type,
            name: data.volume[payload.type],
          });
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  attachDisk({ commit }, params) {
    let url = `/compute/v2.1/servers/${params.instance}/os-volume_attachments`;
    return new Promise((resolve, reject) => {
      const payload = {
        volumeAttachment: {
          volumeId: params.disk,
        },
      };
      OpenStackApi.post(url, JSON.stringify(payload))
        .then(data => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  attachFloatingIpToPort({ commit }, params) {
    // console.log(params);
    let url = `/network/v2.0/floatingips/${params.id}`;
    return new Promise((resolve, reject) => {
      const payload = {
        floatingip: {
          port_id: params.port,
          fixed_ip_address: params.ip,
        },
      };
      OpenStackApi.put(url, JSON.stringify(payload))
        .then(data => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  dettachFloatingIpToPort({ commit }, params) {
    let url = `/network/v2.0/floatingips/${params.ip}`;
    return new Promise((resolve, reject) => {
      OpenStackApi[params.method](url, JSON.stringify(params.float))
        .then(data => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  deleteFloatingIpToPort({ commit }, params) {
    let url = `/network/v2.0/floatingips/${params.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, JSON.stringify(params.float))
        .then(data => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  RenameFloatingIp({ commit }, payload) {
    let url = `/network/v2.0/floatingips/${payload.id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.put(url, JSON.stringify(payload.params))
        .then(({ data }) => {
          commit('updateFloatingIpName', {
            id: payload.id,
            type: payload.type,
            name: data.floatingip[payload.type],
          });
          resolve(data.floatingip);
        })
        .catch(e => reject(e));
    });
  },
  deattachDisk({ commit }, params) {
    let url = `/compute/v2.1/servers/${params.instance}/os-volume_attachments/${params.disk}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url)
        .then(data => {
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  deleteDisk({ commit }, params = {}) {
    let url = `/volume/v3/volumes/${params.disk}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteNetwork({ commit }, params = {}) {
    let url = `/network/v2.0/networks/${params.net}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteRouter({ commit }, params = {}) {
    let url = `/network/v2.0/routers/${params.router}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteBalancer({ commit }, params = {}) {
    let url = `lbaas/v2/loadbalancers/${params.balancer}?cascade=true`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteFirewall({ commit }, params = {}) {
    let url = `/network/v2.0/security-groups/${params.firewall}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteMember({ commit }, params = {}) {
    let url = `lbaas/v2/pools/${params.rule}/members/${params.member}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  addMember({ commit }, params = {}) {
    let url = `lbaas/v2/pools/${params.id}/members`;
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, { member: params.member })
        .then(response => {
          console.log(response.data.member);
          resolve(response.data.member);
        })
        .catch(e => {
          reject(e.response.data.faultstring);
        });
    });
  },
  deleteSubnetRouter({ commit }, params = {}) {
    let url = `/network/v2.0/routers/${params.router}/remove_router_interface`;
    return new Promise((resolve, reject) => {
      const payload = {
        port_id: params.port,
      };
      OpenStackApi.put(url, JSON.stringify(payload))
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteRule({ commit }, params = {}) {
    let url = `/network/v2.0/security-group-rules/${params.rule}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteSubnet({ commit }, params = {}) {
    let url = `/network/v2.0/subnets/${params.net}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deletePort({ commit }, params = {}) {
    let url = `/network/v2.0/ports/${params.port}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteImage({ commit }, params = {}) {
    let url = `/image/v2/images/${params.image}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteSsh({ commit }, params = {}) {
    let url = `/compute/v2.1/os-keypairs/${params.name}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteContainer({ commit }, id) {
    let url = `/secret/v1/containers/${id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteSecret({ commit }, id) {
    let url = `/secret/v1/secrets/${id}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  sendFile({ commit }, payload) {
    // console.log('public');
    return new Promise((resolve, reject) => {
      StorageApi.put(payload.url, payload.file, {
        onUploadProgress: payload.onUploadProgress,
        headers: {
          // 'X-Container-Read': '.r:*,.rlistings',
          'Content-Type': payload?.file?.type,
        },
      })
        .then(response => {
          console.log(response);
          resolve(response.status);
        })
        .catch(e => {
          reject(e);
        })
        .finally(() => {});
    });
  },
  fileToImage({ commit }, params = {}) {
    let url = `/image/v2/images/${params.id}/file`;
    return new Promise((resolve, reject) => {
      // console.log();
      const config = {
        onUploadProgress: params.onUploadProgress,
        headers: {
          'Content-Type': 'application/octet-stream',
          // check: true,
        },
      };
      OpenStackApi.put(url, params.file, config)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  linkToImage({ commit }, params = {}) {
    let url = `/image/v2/images/${params.id}/import`;
    return new Promise((resolve, reject) => {
      OpenStackApi.post(url, params.link)
        .then(({ status }) => {
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  deleteSnapshot({ commit }, params = {}) {
    if (!params.snap) throw new Error('Missed required prop: "snap"');
    let url = `/volume/v3/snapshots/${params.snap}`;
    return new Promise((resolve, reject) => {
      OpenStackApi.delete(url, params)
        .then(({ status }) => {
          // console.log(status);
          resolve(status);
        })
        .catch(e => reject(e));
    });
  },
  getServerInfo({ commit }, id) {
    return new Promise((resolve, reject) => {
      const url = `compute/v2.1/servers/${id}`;
      OpenStackApi.get(url)
        .then(({ data }) => {
          const status = data.server.status;
          const tags = data.server.tags;
          // console.log(tags);
          const task = data.server['OS-EXT-STS:task_state'];
          commit('updateStatus', { id, status, type: 'servers' });
          commit('updateTask', { id, task, type: 'servers' });
          commit('updateServerTags', { id, tags, type: 'servers' });
          resolve(data.server);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // getServerInfo({ commit }, id) {
  //   return new Promise((resolve, reject) => {
  //     const url = `compute/v2.1/servers/${id}/tags`;
  //     OpenStackApi.get(url)
  //       .then(({ data }) => {
  //         console.log(data);
  //         // const status = data.server.status;
  //         // const task = data.server['OS-EXT-STS:task_state'];
  //         // commit('updateStatus', { id, status, type: 'servers' });
  //         // commit('updateTask', { id, task, type: 'servers' });
  //         resolve(data.server);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  deleteServer({ commit }, id) {
    // console.log(id);
    return new Promise((resolve, reject) => {
      const url = `compute/v2.1/servers/${id}`;
      OpenStackApi.delete(url)
        .then(data => {
          resolve(data.status);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteFlavor({ commit }, id) {
    // console.log(id);
    return new Promise((resolve, reject) => {
      const url = `compute/v2.1/flavors/${id}`;
      OpenStackApi.delete(url)
        .then(data => {
          resolve(data.status);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateList({ commit }, payload) {
    if (!payload.silent) commit('SET_IS_LOADING', true);
    const id = payload.id;
    return new Promise((resolve, reject) => {
      const url = `compute/v2.1/servers/${id}`;
      OpenStackApi.get(url)
        .then(({ data }) => {
          // console.log({ data });
          const task = data.server['OS-EXT-STS:task_state'];
          const state = data.server['OS-EXT-STS:vm_state'];
          // console.log(task);
          const status = data.server.status;
          const flavor = data.server.flavor.id;
          commit('updateStatus', { id, status, type: 'servers' });
          commit('updateTask', { id, task, type: 'servers' });
          // commit('updateFlavors', { id, flavor });
          resolve({ status: status, task: task, state: state });
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!payload.silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  checkDiskStatus({ commit }, payload) {
    // if (!payload.silent) commit('SET_IS_LOADING', true);
    const disk = payload.disk;
    return new Promise((resolve, reject) => {
      const url = `volume/v3/volumes/${disk}`;
      OpenStackApi.get(url)
        .then(({ data }) => {
          const id = data.volume.id;
          const status = data.volume.status;
          const attachments =
            data.volume && data.volume.attachments ? data.volume.attachments : null;
          // console.log(attachments);
          commit('updateStatus', { id, status, type: 'volumes' });
          if (attachments) commit('updateAttachment', { id, attachments, type: 'volumes' });
          commit('updateDisk', data.volume);
          // console.log(data.volume.name);
          resolve({ status: data.volume.status, name: data.volume.name });
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          // if (!payload.silent) commit('SET_IS_LOADING', false);
        });
    });
  },
  checkImageStatus({ commit }, id) {
    return new Promise((resolve, reject) => {
      const url = `image/v2/images/${id}`;
      OpenStackApi.get(url)
        .then(({ data }) => {
          const id = data.id;
          const status = data.status;
          const size = data.size;
          commit('updateStatus', { id, status, type: 'privateImages' });
          commit('updateImage', { id, size, type: 'privateImages' });
          resolve(data.status);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {});
    });
  },
  checkSnapshotStatus({ commit }, payload) {
    if (!payload.silent) commit('SET_IS_LOADING', true);
    const disk = payload.disk;
    const snap = payload.snap;
    return new Promise((resolve, reject) => {
      const url = `volume/v3/${disk}/snapshots/${snap}`;
      OpenStackApi.get(url)
        .then(({ data }) => {
          resolve(data.server.status);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!payload.silent) commit('SET_IS_LOADING', false);
        });
    });
  },

  // reset store
  reset({ commit }) {
    setTimeout(() => {
      commit('SET_CURRENT', '');
      commit('SET_LIST', []);
      commit('SET_IS_LOADING', true);
    }, 500);
  },
  resetBalancer({ commit }, delay = 500) {
    return new Promise(resolve => {
      setTimeout(() => {
        commit('loadbalancers', []);
        commit('listeners', []);
        commit('healthmonitors', []);
        commit('pools', []);
        resolve();
      }, delay);
    });
  },
};
