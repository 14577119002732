<template>
  <div class="main-header__wrapper">
    <div ref="header" class="main-header" :class="{ ['main-header--fixed']: fixed }">
      <div class="main-header__inner">
        <div class="main-header__content">
          <div v-if="$slots.default" class="main-header__content-start l-hidden-xs">
            <slot />
          </div>
          <div v-if="$slots.default" class="main-header__balance">
            <a
              :href="`tel:${isAstra ? $t('tel_astra') : $t('tel')}`"
              class="main-header__balance-link"
            >
              <svg-icon name="phone" size="icons" class="main-header__balance-icon" />
              <span class="main-header__balance-text">
                {{ isAstra ? $t('tel_astra') : $t('tel') }}
              </span>
            </a>
          </div>
          <div class="main-header__content-end">
            <!--              v-if="!isAstra && hideButtonCreateNewService"-->
            <transition name="fade">
              <div v-if="showButtonCreateNewService" class="main-header__create l-hidden-sm-down">
                <base-button
                  class="create-button"
                  size="small"
                  color="success"
                  @click="openCreationNav()"
                >
                  {{ $t('create') }}
                </base-button>
              </div>
            </transition>
            <header-basket
              v-if="access['customer.basket'] && !isAstra"
              class="main-header__basket"
            />
            <header-notification class="main-header__notification" />
            <header-notification-support v-if="list" class="main-header__notification" />
            <div class="main-header__block">
              <div class="main-header__block-id" :hint="userName">
                <label :title="userName.length > 19 ? userName : null">{{ userId }}</label>
              </div>
              <div v-if="isShowBalance" class="main-header__block-balance">
                <!--                <router-link class="main-header__block-balance" :to="{ name: 'BillingDeposit' }">-->
                <router-link
                  v-if="access['finance.payment']"
                  class="main-header__block-balance"
                  :to="/*isProviderRusonyx &&
                    notIncludePostpaydServices &&
                    payersNotPhysical &&*/
                  /*isNotReseller ? { name: 'BillingHistory' } : */ { name: 'BillingDeposit' }"
                >
                  <!--              <div class="main-header__balance-label l-hidden-ms-down">-->
                  <div v-if="!isAstra" class="main-header__block-balance-label">
                    {{ $t('balance') }}
                  </div>
                  <div v-if="!isAstra" class="main-header__block-balance-remains">
                    {{ $n(balance, 'currency') }}
                  </div>
                </router-link>
                <div v-else class="main-header__block-balance">
                  <div class="main-header__block-balance-label">
                    {{ $t('balance') }}
                  </div>
                  <div class="main-header__block-balance-remains">
                    {{ $n(balance, 'currency') }}
                  </div>
                </div>
              </div>
            </div>
            <header-profile class="main-header__profile" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNotification from './HeaderNotification/HeaderNotification.vue';
import HeaderNotificationSupport from './HeaderNotificationSupport/HeaderNotificationSupport.vue';
import HeaderProfile from './HeaderProfile/HeaderProfile';
import HeaderCreationNav from './HeaderCreationNav/HeaderCreationNav';
import HeaderBasket from './HeaderBasket/HeaderBasket.vue';
import store from '@/store';
import { postpaid, filterObject, findAllByKeyIncluded } from '@/utils/postpaid';
import provider from '@/mixins/provider';
import updateBalance from '@/mixins/billing/updateBalance';

export default {
  name: 'MainHeader',
  components: {
    HeaderNotification,
    HeaderNotificationSupport,
    HeaderProfile,
    HeaderBasket,
  },
  mixins: [provider, updateBalance],
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rusonyxLogo: true,
    };
  },

  computed: {
    access() {
      return this.$store.state.moduleProfile.access;
    },
    list() {
      return this.$store.getters['moduleSupport/unreaded'].length > 0;
    },
    bindFunc() {
      return this.closeModalWindow.bind(this);
    },
    navHeaderMenu() {
      return Object.keys(this.$store.state.moduleStart.navHeaderMenu);
    },
    userName() {
      return this.$store.getters['moduleProfile/GET_PROFILE_INFO'].name;
    },
    showButtonCreateNewService() {
      return !this.isAstra && this.navHeaderMenu.length !== 0;
    },
    userId() {
      return this.userName.length > 19 ? this.userName.substring(0, 16) + '...' : this.userName;
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    // isNotReseller() {
    //   return !this.$store.getters['moduleStart/reseller'] === true;
    // },
    // notIncludePostpaydServices() {
    //   const re = filterObject(this.$store.getters['moduleStart/services'], value => value > 0);
    //   return findAllByKeyIncluded(re, postpaid).length === 0;
    // },
    // payersNotPhysical() {
    //   return this.$store.getters['moduleBilling/modulePayers/payers'] !== '1';
    // },
    // isProviderRusonyx() {
    //   return this.$store.getters['moduleProviders/provider'] === '3';
    // },
  },
  watch: {
    screenWidth() {
      this.setHeaderHeight();
    },
  },
  mounted() {
    this.$nextTick(this.setHeaderHeight);
    this.$store.dispatch('moduleStart/fetchNavHeaderMenu');
  },
  methods: {
    openCreationNav() {
      this.$modals.open({
        name: 'CreationNav',
        // title: this.$t('newService'),
        component: HeaderCreationNav,
        size: 'small',
        on: {
          click: () => {
            // 'route-change': {
            console.log('click');
            // 'route-change':() => setTimeout(this.$modals.close('CreationNav'), 100),
            setTimeout(this.$modals.close('CreationNav'), 100);
            // },
          },
        },
      });
    },
    setHeaderHeight() {
      this.$store.commit('moduleApp/SET_HEADER_HEIGHT', this.$refs.header.offsetHeight);
    },
    async sendBillMgrAction(params) {
      return store.dispatch('sendBillMgrToolAction', params);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "balance": "Баланс",
      "tel": "+7 (495) 137 07 01",
      "tel_astra": "+7 (495) 741 85 07",
      "create": "Создать +",
      "newService": "Новая услуга",
      "back": "В админпанель"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.main-header {
  position: relative;
  width: 100%;
  height: $header-height-ms;
  background-color: var(--background-theme);
  box-shadow: var(--shadow-box);
  opacity: var(--opacity);
  transition: background $anim-base-duration ease, color $anim-base-duration ease, box-shadow $anim-base-duration ease, border-color $anim-base-duration ease;

  +breakpoint(sm-and-up) {
    height: $header-height;
  }

  +breakpoint(lg-and-up) {
    box-shadow: none;
    border-bottom: 1px solid var(--separator);
  }

  &--fixed {
    position: fixed;
    padding-left: inherit;
    left: 0;
    z-index: 998;
  }

  &__wrapper {
    position: relative;
    width: 100%;
  }

  &__inner {
    align-items: stretch;
    display: flex;
    flex-direction: row;
  }

  &__content {
    flexy(space-between, center);
    flex: 1 1 auto;
    padding: 10px $gutter-ms;
    height: $header-height-ms;

    +breakpoint(sm-and-up) {
      padding: 10px $gutter-sm;
      height: $header-height;
    }

    +breakpoint(ml-and-up) {
      padding: 10px $gutter;
    }

    &-start {
      margin-left: 0;
      margin-right: $gutter-vertical-ms;

      +breakpoint(md-and-up) {
        margin-right: 2rem;
      }
    }

    &-end {
      margin-right: 0;
      flexy(flex-end, center);
      +breakpoint(sm-and-up) {
        margin-left: $gutter-vertical-ms;
      }
      +breakpoint(md-and-up) {
        margin-left: 0;
      }
    }
  }

  &__balance {
    cursor: pointer;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-decoration: none;

    +breakpoint(sm-and-up) {
      margin-left: $gutter-ms;
    }
    +breakpoint(md-and-up) {
      margin-left: 0;
    }
    +breakpoint(ml-and-up) {
      margin-left: auto;
      margin-right: $gutter;
    }

    &-remains {
      min-width: 60px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--header-link);
      transition: color $anim-base-duration ease;
    }

    &-label {
      margin-right: 0.5rem;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      color: $color-light.medium;
    }
    &-link{
      text-decoration: none;
      color: var(--text);
    }
    &-icon {
      display: flex;
      font-size: 13px;
      +breakpoint(sm-and-up) {
        font-size: 15px;
      }
    }
    &-text {
      display: none;
    }
    +breakpoint(ml-and-up) {
      &-text {
        display: flex;
      }
      &-icon {
        display: none;
      }
    }
    @media (min-width: 1280px) and (max-width: 1345px) {
      &-text {
        display: none;
      }
      &-icon {
        display: flex
      }
    }
  }
  &__create {
    margin-right: $gutter-sm;

    +breakpoint(sm-and-up) {
      margin-right: $gutter-xs;
    }

    +breakpoint(md-and-up) {
      margin-right: $gutter-md;
    }
  }
  &__basket {
    margin-right: 0.3rem;
    margin-left: 0.5rem;

    +breakpoint(md-and-up) {
      margin-right: 0.5rem;
      margin-left: 0;
    }
  }
  &__notification {
    //margin: 0 ($gutter-ms / 2);
    margin-right: ($gutter-ms / 2);
  }
  &__block {
    margin-right: 0.2rem;
      //border-left: 1px solid var(--separator);
      padding-left: 0.25rem;
      +breakpoint(sm-and-up) {
        margin-right: $gutter-xs;
      }
      +breakpoint(md-and-up) {
        margin-right: $gutter-sm;
      }
      +breakpoint(md-and-up) {
        padding-left: 10px;
      }
    &-id {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: var(--header-link);
      transition: color $anim-base-duration ease;
      //transition: border-color $anim-base-duration ease;

    }
    &-balance {
      cursor: pointer;
      margin-right: 0.75rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-decoration: none;

      &-remains {
        min-width: 40px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: var(--header-link);
        transition: color $anim-base-duration ease;
      }

      &-label {
        margin-right: 0.5rem;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        color: $color-light.medium;
      }
    }


  }

  &__profile {
    border-left: 1px solid var(--separator);
    padding-left: 0.25rem;
    transition: border-color $anim-base-duration ease;

    +breakpoint(sm-and-up) {
      padding-left: 10px;
    }
  }
}
</style>
