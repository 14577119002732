<template>
  <base-loader v-if="loading" class="loader" />
  <nav-simple-base
    v-else
    :list="headerMenu"
    class="header-creation-nav"
    wide
    @click.native="onClick"
  />
</template>

<script>
import NavSimpleBase from '@/components/NavSimple/NavSimpleBase.vue';
import { orderService } from '@/utils/getServices';
export default {
  name: 'HeaderCreationNav',
  components: { NavSimpleBase },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    headerMenu() {
      const menu = [];
      const listToActualMenu = Object.keys(orderService);
      const objectsMenu = this.$store.state?.moduleStart?.navHeaderMenu;
      if (Object.keys(objectsMenu).length)
        objectsMenu.forEach(item => {
          if (listToActualMenu.includes(item))
            menu.push({
              title: this.$t(item),
              icon: item,
              to: { name: orderService[item] },
            });
        });
      return menu;
    },
    provider() {
      return this.$store.getters['moduleProviders/current'];
    },
  },
  mounted() {
    if (!this.headerMenu.length) this.createNav();
    else this.loading = false;
  },
  methods: {
    async createNav() {
      await this.$store.dispatch('moduleStart/fetchNavHeaderMenu');
      return (this.loading = false);
    },
    onClick(e) {
      this.$emit('click');
      if (e.target.tagName === 'A' && e.target.target !== '_blank') {
        this.$emit('route-change');
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "vds": "VPS сервер",
    "pleskshared": "Виртуальный хостинг Plesk",
    "vhost": "Виртуальный хостинг ISP",
    "vdc3": "Облачный сервер",
    "domain": "Зарегистрировать домен",
    "dummy_ssl_rsnx_oa_migrate": "SSL сертификат",
    "ssl": "SSL сертификат",
    "cdnedge": "CDN",
    "bitrix_license": "Лицензию Bitrix",
    "antiddosgcore": "Защита AntiDDos",
    "licmic": "Лицензия Офис",
    "soft": "Лицензия ispmanager",
    "rps": "Аренда физического сервера",
    "idprotect": "Защита данных владельца домена",
    "licvz": "Лицензия Virtuozzo",
    "licplesk": "Лицензию Plesk",
    "saas_services": "SaaS сервис",
    "mo365": "Лицензия Офис",
    "noservice": "Нет доступных действий",
    "acronis": "Облачный бэкап"
  }
}
</i18n>

<style lang="stylus" scoped>
.header-creation-nav {}
.loader {
  margin-top: 2.5rem;
}
</style>
